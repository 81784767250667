import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message, Input } from 'antd'
import { useLocalStorageState } from 'ahooks'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'

import './index.styl'
import { apiPost } from '../../../utils/http'
import { removeLocalStorage, setLocalStorage } from '../../../utils/utils'
import { useUserInfo } from '../../../hooks/userInfo'
import { getDictList } from '../../../service/service'
import { isMirror } from '../../../utils/utils'

import AnimateBox from './components/animateBox'

const Login = () => {
    let [formData, setFormData] = useState({})
    let history = useHistory()
    const [__, setUserInfo] = useLocalStorageState('user')
    const [_, dispatch] = useUserInfo()

    // 监听表单输入
    const onChangeInput = (e, key) => {
        let value = e
        if (e.target) {
            ;({ value } = e.target)
        }
        const newFormData = Object.assign({}, formData, { [key]: value })
        setFormData(newFormData)
    }

    // 清除登录缓存
    const loginBeforeClean = () => {
        removeLocalStorage('userFunctionList')
        removeLocalStorage('user')
    }
    // 登录
    const login = () => {
        loginBeforeClean()

        if (!formData.username) {
            message.error('请输入账号')
            return
        }
        if (!formData.password) {
            message.error('请输入密码')
            return
        }
        apiPost('/login', {
            username: formData.username,
            password: formData.password
        }).then((res) => {
            const userInfo = res.data
            if (userInfo && !userInfo.disabled && userInfo.id) {
                dispatch({ type: 'updateUserInfo', userInfo: { ...userInfo } })
                getDictList({ type: 'wave' }).then((res) => {
                    setLocalStorage('waveDicList', res?.data || [])
                })
                setUserInfo(userInfo)
                setTimeout(() => {
                    history.replace('/home')
                }, [])
            }
        })
    }
    // 登录-键盘回车
    const onKeyDownToLogin = (e) => {
        if (e.keyCode === 13) {
            login()
        }
    }

    return (
        <div className="login-page">
            <div className="overlay" />

            <AnimateBox />

            <div className="login-box">
                <div className="login-wrapper">
                    <div className="welcome">WELCOME</div>
                    <Input
                        placeholder="请输入账号"
                        className="login-account"
                        prefix={<UserOutlined />}
                        value={formData.username}
                        onChange={(e) => onChangeInput(e, 'username')}
                    />
                    <Input
                        placeholder="请输入密码"
                        className="login-password"
                        prefix={<UnlockOutlined />}
                        value={formData.password}
                        type="password"
                        onChange={(e) => onChangeInput(e, 'password')}
                        onKeyDown={onKeyDownToLogin}
                    />
                    <Button type="primary" className="submit" onClick={login}>
                        登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Login
